import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)
export const constantRoutes = [
    {
        path: '/',
        redirect: '/articleDetail'
    },
    {
        path: '/redirect',
        hidden: true,
        children: [
            {
                path: '/redirect/:path(.*)',
                component: () => import('@/views/redirect/index')
            }
        ]
    },{
        path:'/createArticle',
        component:() => import("../views/createArticle/index")
    },{
        path:'/userArticleDetail',
        component:() => import("../views/userAricleDetail/index")
    },{
        path:'/editArticle',
        component:() => import("../views/editArticle/index")
    },{
        path:'/articleDetail',
        component:() => import("../views/articleDetail/index")
    },{
        path:'/createNotice',
        component:() => import("../views/notice/createNotice")
    },{
        path:'/noticeDetail',
        component:() => import("../views/notice/noticeDetail")
    },{
        path:'/editNotice',
        component:() => import("../views/notice/editNotice")
    }
]

const createRouter = () => new Router({
    mode: 'history',
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes
})

const router = createRouter()
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router