<template>
  <div id="app">
      <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  methods:{
   setScreen(){
     var phoneWidth = parseInt(window.screen.width);
    //  var phoneHeight = parseInt(window.screen.height);
     var phoneScale = phoneWidth/750;//除以的值按手机的物理分辨率
     var ua = navigator.userAgent;
     if (/Android (\d+\.\d+)/.test(ua)) {
         var version = parseFloat(RegExp.$1);
         // andriod 2.3
          if (version > 2.3) {
            document.write('<meta name="viewport" content="width=device-width,initial-scale='+phoneScale+',minimum-scale='+phoneScale+',maximum-scale='+phoneScale+',user-scalable=no">');
             // andriod 2.3以上
        } else {
             document.write('<meta name="viewport" content="width=device-width,user-scalable=no">');
        }
        // 其他系统
     } else {
         document.write('<meta name="viewport" content="width=device-width, initial-scale='+phoneScale+',minimum-scale='+phoneScale+',maximum-scale ='+phoneScale +',user-scalable=no,">');
     }
   }
  },
  created(){
  //  this.setScreen()
  }
}
</script>

<style>
#app,html,body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
  margin: 0;
}
.ql-editor img{
  width: 100%;
  height: 100%;
}
  .time{
      font-size: 14px;
  }
</style>
